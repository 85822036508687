<sysque-frame [componentTitle]="componentTitle">
  <div class="content-container">
    <div class="folder-label">
      <label class="path-label">{{ data.pathLabel }}</label>
      <input
        #input
        type="text"
        [(ngModel)]="folderName"
        class="path-file"
      />
      <button class="icon-button" (click)="onFileSelected()">
        <img class="icon" src="assets/img/Save Search.png" alt="Save Search" />
      </button>
    </div>
    <div class="link-project-container">
      <label class="link-project">{{ data.linkProject }}</label>
      <div class="checkbox-container">
        <input
          type="checkbox"
          id="configCheckbox"
          #configCheckbox
          [(ngModel)]="linkProject"
          (change)="linkConfigurationToProject($event.target.checked)"
        />
      </div>
    </div>
    <div style="margin-top: 10px; width: 100%">
      <alert *ngIf="isAlertVisible" [alert]="alert" (alertClosed)="onDismissClick()"></alert>
    </div>
  </div>

  <div class="bottom-container">
    <dx-button
      id="revert-button"
      class="revert-container"
      text="{{ data.revertName }}"
      (onClick)="onRevertClick()"
    ></dx-button>
    <dx-button
      id="apply-button"
      class="apply-container"
      text="{{ data.applyName }}"
      (onClick)="onApplyClick()"
    ></dx-button>
  </div>
</sysque-frame>
