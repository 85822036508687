<div class="info-container">
  <div class="info-sub-container">
    <div class="info-sub-container2">
      <div class="title-container">
        <div class="title">Family Information</div>
      </div>
      <div class="content-container">
        <div class="left-panel">
          <div class="panel-l1">
            <div class="panel-l2"></div>
            <div class="panel-l3"></div>
            <div class="panel-l4"></div>
          </div>
          <div class="left-container">
            <div class="label-item">{{title}}</div>
            <div class="image-and-properties-container">
              <div class="image-container">
                <modus-button button-style="borderless" class="image-button" (click)="onButtonClick()">
                  <img [src]="image" alt="item image" class="button-image" />
                </modus-button>
              </div>
              <sysque-properties-list class="properties-list-container"
                [propertiesListItems]="propertiesListItems"
                (valueChanged)="onPropertiesValueChanged($event)">
              </sysque-properties-list>
            </div>
            <div class="additional-wire-length-container">
              <div *ngIf="isadditionalWireLengthVisible">
                <sysque-number-box-options-line
                  [numberBoxItems]="additionalWireLengthItems" 
                  [showSpinButtons]="additionalWireLengthSpinButtons"
                  (valueChanged)="onAdditionalWireLengthValueChanged($event)">
                </sysque-number-box-options-line>
              </div>
            </div>
            <div class="instance-description-container">
              <sysque-text-area-description 
                [label]="instanceDescriptionLabel"
                [description]="instanceDescription"
                (valueChanged)="onInstanceDescriptionValueChanged($event)">
              </sysque-text-area-description>
            </div>
            <div class="type-description-container">
              <sysque-text-area-description 
                [label]="typeDescriptionLabel"
                [description]="typeDescription"
                (valueChanged)="onTypeDescriptionValueChanged($event)">
              </sysque-text-area-description>
            </div>
            <div class="pdf-location-project-data-container">
              <div class="pdf-location-container">
                <sysque-pdf-location 
                  [fileName]="fileName" 
                  (attachFileClicked)="onAttachFileClicked()" 
                  (openPdf)="onOpenPdf()">
                </sysque-pdf-location>
              </div>
              <div class="project-data-container">
                <sysque-switch-button 
                  [buttonInfo]="projectDataButtonInfo"
                  (switchClicked)="onProjectDataSwitchClicked($event)"
                  (optionButtonClicked)="onProjectDataButtonClicked()">
                </sysque-switch-button>
              </div>
             </div> 
          </div>
        </div>
        <div class="right-panel">
          <div class="panel-l1">
            <div class="panel-l2"></div>
            <div class="panel-l3"></div>
            <div class="panel-l4"></div>
          </div>
          <div class="right-container">
            <div class="connectors-container">
                <sysque-dropdown-connectors 
                  [title]="connectorsTitle" 
                  [connectors]="connectorsItems"
                  (valueChanged)="onConnectorValueChanged($event)">
                </sysque-dropdown-connectors>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
 
  <div class="action-container">
    <div class="sysque-alert">
      <alert *ngIf="isAlertVisible" [alert]="alert" (alertClosed)="hideAlert()"></alert>
    </div>
      <dx-button 
        id="apply-button" 
        class="done" 
        (onClick)="doneClick()" 
        text="Done">
      </dx-button>
  </div>
</div>