<sysque-frame [componentTitle]="componentTitle">
  <div class="info-container">
    <div [ngClass]="warningMessage === '' ? 'content-container' : 'content-container-alert'">
      <div class="left-panel" style="background: var(--light-background-fill-color-layer-alt, #fff)">
        <div class="left-panel-content">
          <dx-menu
            orientation="vertical"
            [items]="menuItems"
            [focusStateEnabled]="false"
            itemTemplate="items"
            (onItemClick)="handleItemClick($event)"
          >
            <div class="menu-item" *dxTemplate="let item of 'items'">
              <div class="menu-item-container" [ngClass]="{ 'selected': item.name === selectedItem }">
                <div *ngIf="item.name === selectedItem" class="selected-item-border"></div>
                <img class="item-icon" src="assets/img/IconmenuItems.png" alt="logo" />
                {{ item.name }}
              </div>
              <div *ngIf="item.hasSubItems">
                <div
                  *ngFor="let subItem of item.subItems"
                  class="subItems-container"
                  (click)="handleSubItemClick($event, subItem)"
                >
                  <div class="menu-item-container" [ngClass]="{ 'selected': subItem === selectedItem }">
                    <div *ngIf="subItem === selectedItem" class="selected-item-border"></div>
                    <img class="item-icon" src="assets/img/IconmenuItems.png" alt="logo" />
                    {{ subItem }}
                  </div>
                </div>
              </div>
            </div>
          </dx-menu>
        </div>
      </div>

      <div class="split-line"></div>

      <div class="right-panel">
        <div class="right-panel-inner">
          <h2 class="system-type-title">{{ this.title }}</h2>
          <sysque-text-button [button]="importSystemsButtonValues" />
          <div class="list-container">
            <div *ngFor="let column of data.columnsName; let i = index">
              <modus-checkbox
                *ngIf="i === 0 && exportButtonClicked"
                (checkboxClick)="onCheckboxChange($event)"
                class="checkbox-container"
                unchecked
              ></modus-checkbox>
              {{ column }}
            </div>
          </div>
          <dx-list
            [height]="500"
            [selectionMode]="'single'"
            [searchEnabled]="true"
            searchExpr="label"
            searchMode="contains"
            (onOptionChanged)="handleSearch($event)"
            [dataSource]="this.specificItems === true ? this.specificMenuSystems : this.visibleInvisibleSystems"
          >
            <dxo-item-dragging
              group="tasks"
              [allowReordering]="!isSearching && !exportButtonClicked"
              [handle]="exportButtonClicked || isSearching ? '' : '.drag-handle'"
              [onReorder]="reorderLogic"
              [onDragStart]="onDragStart"
            ></dxo-item-dragging>
            <div *dxTemplate="let rowVm of 'item'">
              <div [ngClass]="getRowClass(rowVm)" (click)="toggleSelected(rowVm.index)">
                <system-manager-row-template
                  [rowVm]="rowVm"
                  [isChecked]="isChecked"
                  [exportClicked]="exportButtonClicked"
                  [isSearching]="isSearching"
                  [allSystemsSelected]="allSystemsSelected"
                  [needUpwardMenu]="needContextMenuUpward(rowVm.order)"
                  (checkedChange)="onRowCheckedChange($event)"
                  (switchChange)="onSwitchChange($event)"
                  (contextMenuVisibility)="handleContextMenuVisibility($event)"
                  (deleteSystem)="deleteSystemLogic($event)"
                  (renameSystem)="renameSystem($event)"
                  (duplicateSystem)="duplicateSystem($event)"
                  (setComponentPriority)="setComponentPriority($event)"
                >
                </system-manager-row-template>
              </div>
            </div>
          </dx-list>

          <sysque-text-button [button]="addButtonValues" />
          <div class="export-container">
            <div *ngIf="exportButtonClicked" class="cancel-request-button-container">
              <sysque-text-button [button]="cancelRequestButtonValues"></sysque-text-button>
            </div>
            <div class="export-button-container">
              <sysque-text-button [button]="exportSystemsButtonValues"></sysque-text-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div style="width: 100%; padding-left: 12px; padding-right: 12px">
      <alert *ngIf="showWarning" [alert]="alert" (alertClosed)="onDismissClick()"></alert>
    </div>
  </div>
  <div class="bottom-container">
    <dx-button
      id="discard-button"
      class="discard-button"
      text="Discard Changes"
      (onClick)="onDiscardChangesClick()"
    ></dx-button>
    <dx-button id="apply-button" class="apply-button" text="Save Changes" (onClick)="onSaveChangesClick()"></dx-button>
  </div>
</sysque-frame>
