<sysque-frame [componentTitle]="componentTitle">
  <div class="assign-values-container">
    <div class="assign-values-container-inner">
      <div class="import-btn-inner">
        <sysque-text-button [button]="importButtonValues"></sysque-text-button>
      </div>
      <table id="table-row" class="fixed_header">
        <thead>
          <tr>
            <th style="width: 110px">Size</th>
            <th style="width: 70%">Value</th>
          </tr>
        </thead>
        <tbody>
          <dx-list
            [dataSource]="paramSizes"
            itemTemplate="parameterListItem"
            selectionMode="none"
            [activeStateEnabled]="false"
            [focusStateEnabled]="false"
          >
            <tr *dxTemplate="let item of 'parameterListItem'; let i = index" [ngClass]="getClassForRow(i)">
              <td style="width: 150px">
                <div class="size-lbl">
                  <div class="size-txt">{{ item.id }}"</div>
                </div>
              </td>
              <td>
                <div class="size-value">
                  <modus-text-input
                    #parameterName
                    [ngClass]="{
                      clickable: !isContainSizes,
                    }"
                    (valueChange)="onParameterNameValueChange(parameterName.value, item)"
                    value="{{ item.value }}"
                  ></modus-text-input>
                </div>
              </td>
            </tr>
          </dx-list>
        </tbody>
      </table>
      <div class="export-btn-c">
        <div class="export-btn-inner">
          <sysque-text-button [button]="exportButtonValues"></sysque-text-button>
        </div>
      </div>
      <div class="alert-c">
        <div class="sysque-alert">
          <alert *ngIf="warningMessage" [alert]="alert" (alertClosed)="onDismissClick()"></alert>
        </div>
      </div>
      <div class="h-line"></div>
      <div class="b-inner-container">
        <div class="action-container">
          <dx-button id="apply-button" class="apply" text="Apply" (onClick)="saveData()"> </dx-button>
        </div>
      </div>
    </div>
  </div>
</sysque-frame>
