<div
  #categoryContainer
  [ngClass]="{ 'category-container': true, 'category-container-manage-mode': manageMode ? true : false }"
>
  <div *ngIf="!addFittingMode && !manageMode" class="category-opt">
    <div class="select-box-container" #selectBoxContainer>
      <dx-select-box
        width="185px"
        [items]="categoryList$ | async"
        [readOnly]="false"
        [disabled]="false"
        [value]="selectedCategory"
        displayExpr="tabName"
        valueExpr="tabName"
        (onValueChanged)="onCategoryChanged($event)"
      >
        <div *dxTemplate="let data of 'dropDownButton'">
          <img alt="Custom icon" src="assets/img/chevron-down-med.svg" class="custom-select-system-icon" />
        </div>
      </dx-select-box>
    </div>
  </div>

  <div
    [ngStyle]="{
      'width': '-webkit-fill-available',
      'height': (addFittingMode || manageMode) ? '55vh' : 'unset'
    }"
    [ngClass]="{ 'category-img-container': true, 'category-img-container-manage-mode': manageMode }"
  >
    <div
      class="fitting"
      *ngFor="let item of fittingList; let i = index"
      id="target-button-{{ key }}-{{ i }}"
      [ngClass]="{
        'copy-state-div': item.isCopyState,
        'no-copy-state-div': !item.isCopyState,
        'mds-btn-img': getImageLabel(item).hasDescription,
        'mds-btn-img-none': !imageLabel.hasDescription,
        'disabled-mode': item.isDisabled
      }"
      [style.border]="item.isDefault && !item.isDisabled ? '2px solid #005FB8' : 'none'"
      (click)="onItemClick(item)"
      draggable="true"
      (dragstart)="onDragStart($event, i)"
      (dragover)="onDragOver($event)"
      (drop)="onDrop($event, i)"
    >
      <div
        class="position-relative cursor-pointer"
        [ngClass]="{
          'mds-btn': imageLabel.hasDescription,
          'mds-btn-none': !imageLabel.hasDescription,
          disabled: item.isDisabled
        }"
        id="modus-button-{{ key }}-{{ i }}"
        (mouseenter)="tooltipMouseEnter(i)"
        (mouseleave)="tooltipMouseLeave($event, i)"
      >
        <img
          id="s_{{ item.displayPosition }}-{{ key }}"
          [ngClass]="{}"
          [src]="item.image ? 'data:image/png;base64,' + item.image : 'assets/img/unavailable-content.png'"
          class="img-borderless"
          alt="logo"
        />
        <ng-container *ngIf="hoveredItemIndex === i">
          <sysque-tooltip
            class="position-absolute"
            [tooltipData]="getTooltipInputData(item, key)"
            (mouseenter)="tooltipContainerMouseEnter()"
            (mouseleave)="tooltipContainerMouseLeave()"
          ></sysque-tooltip>
        </ng-container>
      
      </div>

      <div *ngIf="imageLabel.hasDescription" class="image-label">{{ imageLabel.label }}</div>

      <dx-context-menu
        class="context-menu"
        [dataSource]="defaultItems"
        target="#target-button-{{ key }}-{{ i }}"
        (onShowing)="onContextMenuShowing($event, item)"
        (onShown)="updateMenuItems(item)"
        (onItemClick)="onContextMenuItemClick($event, item, i)"
      >
        <div *dxTemplate="let e of 'item'">
          <div class="sysque-item-container">
            <div></div>
            <div>
              {{ e.text }}
            </div>
            <span *ngIf="e.items" class="dx-icon-spinright menu-icon-font-size"></span>
          </div>
        </div>
      </dx-context-menu>

      <div *ngIf="isDrawing" class="loading-overlay"></div>
    </div>
  </div>

  <sysque-add-fitting-btn
    *ngIf="!addFittingMode && !manageMode"
    (notify)="addFittingClickedNotification()"
  ></sysque-add-fitting-btn>

  <div *ngIf="isError" class="loading-overlay">
    <dx-dialog id="errorDownload">
      <div class="dialog-content">
        <p class="dialog-title">{{ dialogTitle }}</p>
        <p class="dialog-body">{{ dialogBody }}</p>
      </div>
      <div class="button-grid">
        <button class="button-yes-no" (click)="onOkClick()">{{ buttonName }}</button>
        <button class="button-yes-no" [hidden]="isButtonNoDisabled" (click)="onNoClick()">No</button>
      </div>
    </dx-dialog>
  </div>

  <div *ngIf="isLoading" class="loading-overlay">
    <div style="z-index: 2; width: 70%">
      <sysque-progress-bar
        #sysqueProgress
        [actionName]="progressBarTitle"
        [actionText]="progressBarBody"
        [value]="false"
      ></sysque-progress-bar>
    </div>
  </div>
</div>
