<div class="c-search-container">

  <sysque-select-box
   
    class="add-5-padding"
    [data]="groups"
    [key]="'name'"
    [showClearButton]="showClearButton"
    placeholder="Category Type"
    (valueChanged)="onValueChanged($event,groups, 'componentGroupID')"
    [searchEnabled]="true"
  ></sysque-select-box>

  <sysque-select-box
   
  class="add-5-padding"
  [data]="manufacturers"
  [key]="'name'"
  [showClearButton]="showClearButton"
  placeholder="Manufacturer"
  (valueChanged)="onValueChanged($event,manufacturers, 'manufacturerID')"
  [searchEnabled]="true"
  ></sysque-select-box>

<sysque-select-box
   
class="add-5-padding"
[data]="productTypes"
[key]="'name'"
[showClearButton]="showClearButton"
placeholder="Product Type"
(valueChanged)="onValueChanged($event,productTypes, 'productDisciplineID')"
[searchEnabled]="true"
></sysque-select-box>

<sysque-select-box
   
class="add-5-padding"
[data]="materialTypes"
[key]="'name'"
[showClearButton]="showClearButton"
placeholder="Material"
(valueChanged)="onValueChanged($event,materialTypes, 'materialID')"
[searchEnabled]="true"
></sysque-select-box>

<sysque-select-box
   
class="add-5-padding"
[data]="installTypes"
[key]="'name'"
[showClearButton]="showClearButton"
placeholder="Install Type"
(valueChanged)="onValueChanged($event, installTypes, 'installTypeID')"
[searchEnabled]="true"
></sysque-select-box>

  <modus-text-input
    value="{{ curateSearchVm.searchWords }}"
    #searchValue
    class="add-5-padding add-font"
    placeholder="Search"
    (valueChange)="onUpdateSearchValue(searchValue.value)"
    include-search-icon
    [ngClass]="{ 'add-font': true }"
  >
  </modus-text-input>
  <div class="search-container">
    <div style="width: 60%"></div>

    <button  [ngClass]="{'search-button-enable':true, 'search-button-disable':isSearchButtonDisabled}"
        [disabled]="isSearchButtonDisabled" (click)="onSearchClick()">
      <div [ngClass]="{'search-label-enable':true, 'search-label-disable':isSearchButtonDisabled}">Search</div>
    </button>

  </div>
</div>
