<div class="dropdown-connectors-container">
  <div *ngIf = "hasTitle" class="dropdown-connectors-title">
    <label class="dropdown-label">{{title}}</label>
  </div>
  <div class="dropdown-connectors-content">
    <div *ngFor="let item of items; index as i" class="dropdown-connector">
      <label class="dropdown-connectors-label">{{item.label}}</label>
      <sysque-select-box class="dropdown-connectors-select-box" 
        [data]="item.data" 
        [placeholder]="item.placeholder"
        [width]="item.width" 
        [height]="item.height" 
        (valueChanged)="onValueChanged($event.value, item.label, i)"
        [value]="item.selectedValue" 
        [dropDownOptions]="dropDownOptions">
      </sysque-select-box>
    </div>
  </div>
</div>