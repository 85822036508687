<div class="number-box-container">
    <label class="number-box-label">{{label}}</label>
    <div class="number-box-buttons-container">
    <div *ngFor="let item of items">
        <div class="dx-field">
            <div class="dx-field-label">{{item.label}}</div>
            <div class="dx-field-value">
              <dx-number-box
                [value]="item.value"
                [showSpinButtons]="showSpinButtons"
                [format]="item.unit"
                [height]="item.height"
                [width]="item.width"
                [min]="item.minValue"
                [disabled]="item.disabled"
                (onValueChanged)="onValueChanged($event.value, item.label)">
                <dxi-button name="spins"></dxi-button>
              </dx-number-box>
            </div>
          </div>
        </div>
    </div>
</div>  