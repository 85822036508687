<sysque-frame [componentTitle]="componentTitle">
  <div class="content-container" [style.height]="contentHeight">
    <div class="message">
      <div>
        Do you wish to delete <span class="message-sys"> [{{ data.msgSystemCategorymName }}] </span> and associated data
        ?
      </div>
    </div>
    <div class="alert-div">
      <alert *ngIf="isAlertVisible" [alert]="alert" (alertClosed)="onDismissClick()"></alert>
    </div>
  </div>

  <div class="bottom-container">
    <dx-button class="delete-button-cancel" text="Cancel" (click)="closeWindow()"> </dx-button>
    <dx-button class="delete-button-proceed" text="Proceed" (click)="deleteSystemCategory()"> </dx-button>
  </div>
</sysque-frame>
