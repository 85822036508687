<div #parentContainer style="width: '{{width}}'">
<sysque-select-box
    #selectBox
    [data]="systemList"
    [key]="key"
    [disabled]="disabled"
    [showClearButton]="showClearButton"
    [placeholder]="placeholder"
    [width]="width"
    [height]="height"
    (valueChanged)="onValueChanged($event)"
    [(value)]="selectedSystem"
    [dropDownOptions]="{
      width: this.calculatedWidth, 
      resizeEnabled: true,
      minWidth: 380
    }"
></sysque-select-box>
</div>