<div class="cable-tray-container">
  <div class="cont-1">
    <div class="cont-2">
      <div class="cont-3">
        <div class="project-name">
          <dx-select-box #projectSelectBoxRef 
            [items]="projectList" 
            [readOnly]="false" 
            [disabled]="false"
            placeholder="Project Name" 
            (onFocusIn)="onFocusIn()"
            (onOpened)="onProjectOpened()" 
            (onValueChanged)="onProjectValueChanged($event)">
            <div *dxTemplate="let data of 'dropDownButton'">
              <img alt="Custom icon" src="assets/img/chevron-down-med.svg" class="custom-select-system-icon" />
            </div>
          </dx-select-box>
        </div>
      </div>
    </div>
  </div>

  <div class="p-container">
    <div class="project-name">
      <dx-select-box #wireFillSelectBoxRef [items]="wireSpecList" [readOnly]="false" [disabled]="false"
        (onOpened)="onWireFillSpecOpened()" placeholder="Wire Fill Specification"
        (onValueChanged)="onWireFillSpecValueChanged($event)">
        <div *dxTemplate="let data of 'dropDownButton'">
          <img alt="Custom icon" src="assets/img/chevron-down-med.svg" class="custom-select-system-icon" />
        </div>
      </dx-select-box>
    </div>
  </div> 

  <div class="clearance-chbx-container">
    <modus-checkbox class="modus-checkbox-r" (checkboxClick)="onCableTrayValueChanged($event)" *ngIf="needToShow"
      [checked]="isClearance" checked label="Cable Tray Clearance"> </modus-checkbox>
  </div>

  <div class="direction-container mt-1" *ngIf="isClearance">
    <modus-text-input label="Left" placeholder="" (focusout)="onFocusOutEvent($event,leftInputValue.label)"
      inputmode="numeric" #leftInputValue class="direction-input-width"></modus-text-input>

    <modus-text-input label="Right" placeholder="" inputmode="numeric"
      (focusout)="onFocusOutEvent($event,rightInputValue.label)" #rightInputValue class="direction-input-width">
    </modus-text-input>

    <modus-text-input label="Top" placeholder="" inputmode="numeric"
      (focusout)="onFocusOutEvent($event,topInputValue.label)" #topInputValue class="direction-input-width">
    </modus-text-input>

    <modus-text-input label="Bottom" placeholder="" inputmode="numeric"
      (focusout)="onFocusOutEvent($event,bottomInputValue.label)" #bottomInputValue class="direction-input-width">
    </modus-text-input>
  </div>

  <div class="apply-container mt-2">
    <dx-button class="button-apply apply-section" text="Apply to Selection" (onClick)="applySelection()"
      [disabled]="disableApplyBtn"></dx-button>
  </div>
</div>