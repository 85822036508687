<div class="add-parameter-container">
  <div class="title-container">
    <div class="title">Add Project Parameter</div>
  </div>
  <div class="inner-container">
    <div class="flex-c-1">
      <div class="flex-c-2">
        <div class="p-type-container">
          <div class="p-title">
            <div class="p-title-txt">Parameter Type</div>
          </div>
          <dx-radio-group
            [items]="['Shared', 'Project']"
            [value]="'Shared'"
            layout="horizontal"
            (onValueChanged)="onParameterTypeValueChanged($event)"
          >
          </dx-radio-group>
        </div>
        <div class="item-container">
          <div class="t-label">Name</div>
          <modus-text-input
            style="width: 203px"
            #parameterName
            id="parameterNameText"
            (valueChange)="onParameterNameValueChange(parameterName.value)"
          ></modus-text-input>
        </div>
        <div class="item-container">
          <div class="t-label">Discipline</div>

          <dx-select-box
            [items]="disciplines"
            (onValueChanged)="onDisciplineValueChanged($event)"
            [value]="disciplines[0] ?? ''"
          >
            <div *dxTemplate="let data of 'dropDownButton'">
              <img alt="Custom icon" src="assets/img/chevron-down-med.svg" class="custom-select-system-icon" />
            </div>
          </dx-select-box>
        </div>
        <div class="item-container">
          <div class="t-label">Parameter Category</div>
          <dx-select-box
            [items]="parameterCategories"
            (onValueChanged)="onParameterCategoryValueChanged($event)"
            [value]="parameterCategories[0] ?? ''"
          >
            <div *dxTemplate="let data of 'dropDownButton'">
              <img alt="Custom icon" src="assets/img/chevron-down-med.svg" class="custom-select-system-icon" /></div
          ></dx-select-box>
        </div>
        <div class="item-container">
          <div class="t-label">Parameter Group</div>
          <dx-select-box
            [items]="parameterGroups"
            (onValueChanged)="onParameterGroupValueChanged($event)"
            [value]="selectedParameterGroup ?? ''"
          >
            <div *dxTemplate="let data of 'dropDownButton'">
              <img alt="Custom icon" src="assets/img/chevron-down-med.svg" class="custom-select-system-icon" /></div
          ></dx-select-box>
        </div>
        <div class="item-container" *ngIf="isProjectParameter">
          <div class="t-label">Grouping</div>
          <dx-select-box
            [items]="groupings"
            (onValueChanged)="onGroupingValueChanged($event)"
            [value]="selectedParameterGrouping ?? ''"
          >
            <div *dxTemplate="let data of 'dropDownButton'">
              <img alt="Custom icon" src="assets/img/chevron-down-med.svg" class="custom-select-system-icon" /></div
          ></dx-select-box>
        </div>
      </div>
      <div class="c-list-container">
        <dx-list
          #categoriesList
          [dataSource]="categories"
          [height]="400"
          [selectionMode]="selectionModeValue"
          [selectAllMode]="selectAllModeValue"
          [showSelectionControls]="true"
          [(selectedItemKeys)]="selectedParameterCategories"
          [selectByClick]="selectByClick"
          (onInitialized)="onInitialized($event)"
          (onSelectionChanged)="onCategoriesListSelectionChanged($event)"
        ></dx-list>
      </div>
    </div>
  </div>
  <div class="alert-c" *ngIf="warningMessage">
    <div class="alert-inner-c">
      <div class="sysque-alert">
        <alert [alert]="alert" (alertClosed)="onDismissClick()"></alert>
      </div>
    </div>
  </div>
  <div class="b-line"></div>
  <div class="b-inner-container">
    <div class="action-container">
      <dx-button id="apply-button" class="apply" text="Apply" (onClick)="saveData()"> </dx-button>
    </div>
  </div>
</div>
