<div class="main-div position-absolute" ngDraggable>
  <button class="close-btn" (click)="closeDialog()">✖</button>
  <div class="dialog position-relative">
    <div class="content">
      <h2 class="mb-4">Line Graphics</h2>
      <div class="d-flex mb-4 align-items-center">
        <label class="flex-1 mb-0" for="color-picker">Color:</label>
        <div class="color-toggle d-flex flex-row flex-1 border">
          <div class="d-flex align-items-center">
            <input #ignoredInput class="color-picker-input"
              [(colorPicker)]="currentColor"
              [style.background]="currentColor"
              (colorPickerChange)="onColorChange($event)"
              [cpIgnoredElements]="[ignoredButton, ignoredInput]"
              [(cpToggle)]="toggle"
              [cpOKButton]="true"
              [cpOKButtonText]="'Apply'"
              [cpOKButtonClass]="'btn btn-primary'"
              [cpCancelButton]="true"
              [cpCancelButtonText]="'Discard'"
              [cpCancelButtonClass]="'btn'"
            />
          </div>
          <div class="toggle-color-picker" #ignoredButton (click)="toggle=!toggle">
            <img class="mt-2" src="assets/img/ColorPickerIcon.png" alt="Color Picker">
          </div>
        </div>
      </div>
      <div class="pattern-container">
        <label class="flex-1 mb-0" for="pattern">Pattern:</label>
        <sysque-select-box
          [data]="patterns"
          [placeholder]="'Pattern'"
          [width]="200"
          [height]="30"
          (valueChanged)="onPatternChange($event.value)"
          [value]="currentPattern"
          [dropDownOptions]="{
            width: calculatedWidth,
            resizeEnabled: true,
            minWidth: 100,
            maxWidth: 500
          }">
        </sysque-select-box>
      </div>
      <div class="d-flex mb-4 align-items-center">
        <label class="flex-1 mb-0" for="line-weight">Line Weight:</label>
        <input class="flex-1 line-weight border" type="number" id="line-weight" [(ngModel)]="currentLineWeight" min="1" max="16" />
      </div>
      <div class="actions d-flex">
        <button class="btn btn-text-primary mr-1 flex-1" (click)="discardChanges()">Discard</button>
        <button class="btn btn-primary ml-1 flex-1" (click)="applyChanges()">Apply</button>
      </div>
    </div>
  </div>
</div>
