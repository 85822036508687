<sysque-frame [componentTitle]="componentTitle">
  <div class="content">
    <dx-select-box
      class="chevrondownmed1"
      [dataSource]="projectList"
      [value]="projectList[projectIndex] ?? ''"
      (onValueChanged)="onProjectValueChanged($event)"
      placeholder="Project Name"
      (onOpened)="onProjectOpened()"
    >
      <div *dxTemplate="let data of 'dropDownButton'">
        <img alt="Custom icon" src="assets/img/chevron-down-med.svg" class="custom-select-system-icon" />
      </div>
    </dx-select-box>
    <dx-select-box
      #panelSelectBoxRef
      class="chevrondownmed1"
      [dataSource]="panelList"
      [value]="selectedPanel"
      (onValueChanged)="onPanelValueChanged($event)"
      placeholder="Panel Name"
      (onOpened)="onPanelOpened()"
    >
      <div *dxTemplate="let data of 'dropDownButton'">
        <img alt="Custom icon" src="assets/img/chevron-down-med.svg" class="custom-select-system-icon" />
      </div>
    </dx-select-box>

    <div class="content-inner">
      <div class="header-container-parent">
        <div class="header-container">
          <div class="header-text">Circuit Names</div>
        </div>

        <div class="items-parent">
          <div class="items-container">
            <div class="box-container">
              <div class="items" *ngFor="let item of circuitList">
                <modus-checkbox
                  style="padding-top: 3px"
                  (checkboxClick)="onValueChanged($event, item)"
                  [checked]="item.isSelected"
                  [label]="item.circuitName"
                ></modus-checkbox>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div style="width: 100%">
      <alert *ngIf="warningMessage" [alert]="alert" (alertClosed)="onDismissClick()"></alert>
    </div>
  </div>

  <span class="btnbutton-grid" style="background: rgba(243, 243, 243, 0.5)">
    <dx-button
      class="apply-button btnbutton-button btnmin-width"
      text="Apply"
      (onClick)="saveData()"
      [disabled]="isApplyButtonDisabled"
    ></dx-button>
  </span>
</sysque-frame>
