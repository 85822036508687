<sysque-frame [componentTitle]="componentTitle">
  <div
    class="content-container"
    [ngClass]="{
      'content-container-system-alert': isAlertOn && windowType === 'System',
      'content-container-system-non-alert': !isAlertOn && windowType === 'System',
      'content-container-category-alert': isAlertOn && windowType === 'Category',
      'content-container-category-non-alert': !isAlertOn && windowType === 'Category',
    }"
  >
    <div class="text-name-container">
      <input
        type="text"
        [placeholder]="textboxPlaceHolder"
        [(ngModel)]="systemOrCategoryName"
        class="text-name-input"
        pattern="[a-zA-Z].*"
        (input)="onUserInputChanged()"
      />
    </div>
    <dx-select-box
      *ngIf="!shown"
      class="input-selectbox"
      [items]="categories"
      displayExpr="text"
      [showClearButton]="true"
      [placeholder]="'System Type'"
      (onValueChanged)="getSelectedValue($event)"
      [disabled]="shouldDisableShapeSelection()"
      [value]="categories[categoryIndex]"
    >
      <div *dxTemplate="let data of 'item'">
        {{ data.text }}
      </div>
    </dx-select-box>

    <div style="margin-top: 20px; width: 100%">
      <alert *ngIf="isAlertOn" [alert]="alert" (alertClosed)="onDismissClick()"></alert>
    </div>
  </div>

  <div class="bottom-container">
    <dx-button
      class="system-button"
      [ngClass]="{ 'disabled-system-button': isCreateButtonDisabled }"
      [disabled]="isCreateButtonDisabled"
      (click)="create()"
      text="{{ data.buttonLabel }}"
    >
    </dx-button>
  </div>
</sysque-frame>
