<div class="d-flex flex-row align-items-center main-div" [ngClass]="alertClass">
  <div class="d-flex p-2">
    <i class="modus-icons icon-size" aria-hidden="true" [ngClass]="{
      'icon-red': icon === 'cancel_circle',
      'icon-brown': icon === 'warning',
      'icon-blue': icon === 'info',
      'icon-green': icon === 'check_circle'
    }">
      {{ icon }}
    </i>
    <div class="flex-grow-1">
      <h4 class="alert-title pl-2 mb-1" [innerHTML]="title"></h4>
      <p class="alert-message pl-2" [innerHTML]="message" [ngStyle]="{ 'max-height': hasMaxHeight ? alert.maxHeight : null }">
      </p>
    </div>
    <div class="close-button-wrapper ml-auto">
      <i *ngIf="isCloseButtonVisible" (click)="hideAlert()" class="modus-icons close-button" aria-hidden="true">close</i>
    </div>
  </div>
</div>