export enum AlertStatus {
  Error,
  Warning,
  Info,
  Success
}

export interface IAlert {
  message: string;
  type: AlertStatus;
  title?: string;
  isDismissable?: boolean;
  maxHeight?: string;
}