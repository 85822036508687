<div
  style="margin: 0; padding: 0; display: flex; flex-direction: column;"
  [ngClass]="{
    's-panel-frame': true,
    'addFitting-mode': addFittingMode ? true : false,
    'manage-mode': manageMode ? true : false
  }"
>
  <div
    style="
      margin: 0;
      padding: 0;
      display: flex;
      flex-direction: column;
      min-height: 98vh;
      width: -webkit-fill-available;
    "
  >
    <div style="display: flex; height: 30px; width: -webkit-fill-available">
      <sysque-select-system
        (valueChanged)="onValueChanged($event)"
        [systemInfo$]="stateSystemInfo$"
        [selectedSystem]="dockedState.selectedSystem"
        [panelName]="'systemPanel'"
        style="width: -webkit-fill-available"
      ></sysque-select-system>
      <div class="group-context-menu">
        <div class="group-btn">
          <modus-button button-style="borderless" (click)="contextMenu.contextMenuVisibility($event)">
            <img alt="logo" src="assets/img/Group.png" style="transform: rotate(-90deg)" />
          </modus-button>
        </div>
        <sysque-context-menu
          #contextMenu
          target="#modus-button"
          (contextMenuItemSelected)="handleContextMenuItem($event)"
          [menuItems]="menuItems"
        ></sysque-context-menu>
      </div>
      <div *ngIf="isLoading" class="loading-overlay">
        <div style="z-index: 2; width: 70%">
          <sysque-progress-bar
            #sysqueProgress
            [actionName]="progressBarTitle"
            [actionText]="progressBarBody"
            [value]="false"
          ></sysque-progress-bar>
        </div>
      </div>

      <div *ngIf="areMissing || isError" class="loading-overlay">
        <dx-dialog id="errorDownload">
          <div class="dialog-content">
            <p class="dialog-title">{{ dialogTitle }}</p>
            <p class="dialog-body">{{ dialogBody }}</p>
          </div>
          <div class="button-grid">
            <button class="button-yes-no" (click)="onYesClick()">{{ buttonOkOrYes }}</button>
            <button class="button-yes-no" [hidden]="isButtonNoDisabled" (click)="onNoClick()">{{ buttonNo }}</button>
          </div>
        </dx-dialog>
      </div>
    </div>
    <modus-checkbox
      *ngIf="showAddFittingBtn && !manageMode && !addFittingMode"
      [checked]="dockedState.isHangerNeeded"
      (checkboxClick)="onHangertValueChanged($event)"
      class="modus-checkbox-r hanger-check"
      checked
      [label]="hangerNeededLabel"
    ></modus-checkbox>

    <ng-container style="height: 350px">
      <sysque-panel-system
        *ngIf="isPanelSystem && showAddFittingBtn && !manageMode && !addFittingMode"
        [checkBoxConfigs]="checkBoxConfigs"
        [panelsList]="panelsList"
        (panelValueChanged)="onPanelValueChanged($event)"
        (panelFocusIn)="onPanelFocusIn()"
        (checkedCircuit)="getUpdatedCircuit($event)"
      ></sysque-panel-system>

      <sysque-cable-tray-system
        [addFittingMode]="false"
        *ngIf="!isPanelSystem && !addFittingMode && showAddFittingBtn && !manageMode && !addFittingMode"
        (onProjectChanged)="onProjectValueChanged($event)"
        [projectList]="projectList"
        [wireSpecList]="wireSpecList"
        [isClearance]="dockedState.isCableTrayClearanceNeeded"
        (updateIsClearance)="updateIsClearance($event)"
        (projectFocusIn)="onProjectFocusIn()"
        [needToShow]="showClearance"
        [isClearance]="dockedState.isCableTrayClearanceNeeded"
        (numberBoxItemsChanged)="onTextInputChanged($event)"
        (onWireFillChanged)="onWireFillValueChanged($event)"
        [needToShow]="showClearance"
      ></sysque-cable-tray-system>

      <div class="apply-container mt-2" *ngIf="isPanelSystem">
        <dx-button
          class="button-apply apply-section"
          [text]="applyToSelectionButton"
          (onClick)="applyPanelData()"
          [disabled]="disableApplyBtn"
          [visible]="showApplyBtn"
        ></dx-button>
      </div>

      <div class="v-line" *ngIf="showApplyBtn"></div>

      <sysque-category-scope
        id="right-click-target"
        (contextmenu)="onRightClick($event)"
        [key]="'sp'"
        *ngIf="showAddFittingBtn"
        [selectedSystem]="dockedState.selectedSystem"
        [addFittingMode]="false"
        [manageMode]="false"
        [categoryList$]="categoryList$"
        [fittingList]="dockedState.fittingList"
        [selectedCategory]="dockedState.selectedCategory"
        (categoryChanged)="onCategoryChanged($event)"
        (copiedItemListChanged)="updateCopiedItemList($event)"
      >
      </sysque-category-scope>

      <dx-context-menu
        *ngIf="showRightClickMenu"
        class="right-context-menu"
        [dataSource]="rightClickMenuItems"
        target="#right-click-target"
        (onShowing)="onContextMenuShowing($event)"
        (onItemClick)="onContextMenuItemClick($event)"
      >
      </dx-context-menu>
    </ng-container>

    <div
      [ngClass]="{
'std-position': addFittingMode ? false : true,
'manage-mode-position': addFittingMode || manageMode ? true : false,
}"
    >
      <sysque-root-path-selector *ngIf="showRootPath" [manageMode]="false"></sysque-root-path-selector>
    </div>
  </div>
</div>
