<sysque-frame [componentTitle]="componentTitle">
  <div class="body-wrapper">
    <div class="body">{{ convertSystemBody }}</div>
  </div>
  <div class="content-wrapper">
    <div class="icon-and-text">
      <div class="text">
        <strong>[{{ systemName }}]</strong>
      </div>
    </div>

    <hr class="divider"/>

    <div *ngIf="showAlignmentOptions" class="coupling-aligment-container">
      <label class="alignment-label">{{ selectCouplingAlignmentLabel }}</label>
      <div class="radio-button-container">
        <mat-radio-group [(ngModel)]="selectedAlignment" (change)="alignmentChange($event)">
          <div class="radio-button" *ngFor="let item of alignmentOptions; let i = index">
            <img [src]="item.icon" class="img-borderless" alt="logo" />
            <mat-radio-button class="radio-button-content" [value]="item.text" [checked]="item.text === selectedAlignment">
              {{ item.text }}
            </mat-radio-button>
          </div>
        </mat-radio-group>
      </div>
    </div>

    <div class="checkbox-checkbox">
      <div class="checkbox-wrapper">
        <modus-checkbox
          (checkboxClick)="onRetainValueChanged($event)"
          [checked]="isRetain"
          [label]="retainExistingObjectData"
        ></modus-checkbox>
      </div>
    </div>

    <div class="icon-and-text-parent">
      <div class="icon-and-text1" *ngFor="let item of itemList">
        <div>
          <img class="img-class" src="assets/img/list-bulleted.png" alt="" />
        </div>
        <div class="body2">
          <strong>{{ item }}</strong>
        </div>
      </div>
    </div>

    <div class="alert-container">
      <alert *ngIf="isAlertVisible" [alert]="alert" (alertClosed)="hideAlert()"></alert>
    </div>
  </div>

  <div class="button-grid">
    <modus-checkbox
      class="ignore-checkbox"
      (checkboxClick)="onIgonreValueChanged($event)"
      [checked]="isIgnore"
      [label]="ignoreMessage"
    ></modus-checkbox>
    <dx-button class="button-button text" text="{{ buttonContent }}" (onClick)="saveData()"> </dx-button>
  </div>
</sysque-frame>
