<div class="service-type-container">
  <sysque-select-box
    class="dropdown-select-box"
    [data]="data"
    [disabled]="disabled"
    [placeholder]="placeholder"
    [width]="width"
    [height]="height"
    (valueChanged)="onValueChanged($event.value)"
    [value]="serviceTypeName"
    [dropDownOptions]="{
      width: this.calculatedWidth,
      resizeEnabled: true,
      maxWidth: 500
    }"
  >
  </sysque-select-box>

  <div class="group-btn-container">
    <modus-button button-style="borderless" (click)="handleContextMenu($event)">
      <img src="assets/img/Group.png" style="transform: rotate(-90deg)" alt="logo" />
    </modus-button>
  </div>
</div>
