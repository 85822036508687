<div class="add-fittings-container-p">
  <div class="add-fittings-container">
    <div class="add-fitting-tab">
      <sysque-add-fitting-tab #tabPanel></sysque-add-fitting-tab>
    </div>

    <div class="v-line"></div>

    <div class="fitting-result">
      <sysque-result-container></sysque-result-container>
    </div>

    <div class="v-line"></div>

    <div *ngIf="showDialog" class="loading-overlay">
      <dx-dialog>
        <div class="dialog-content">
          <p class="dialog-title">{{ dialogTitle }}</p>
          <p class="dialog-body">{{ dialogContent }}</p>
        </div>

        <span>
          <modus-checkbox
            class="replaceCheckBox"
            (checkboxClick)="onReplaceAll($event)"
            [checked]="replaceAll"
            [label]="'Do this for additional occurrences'"
          ></modus-checkbox>
        </span>
        <div class="button-grid">
          <button class="button-cancel-proceed" (click)="onCancelClick()">Cancel</button>
          <button class="button-cancel-proceed" (click)="onReplaceClick()">Proceed</button>
        </div>
      </dx-dialog>
    </div>
    <div [ngClass]="{ 'v-line': true, 'v-line-manage-mode': true }"></div>

    <div>
      <sysque-add-fitting-panel-frame
        [addFittingMode]="true"
        [manageMode]="false"
        [showRootPath]="true"
        (pasteError)="onPasteError($event)"
        (showDuplicateError)="onShowDuplicateError($event)"
        (onShowInEnclosingFolder)="onShowInEnclosingFolder($event)"
      ></sysque-add-fitting-panel-frame>
    </div>
  </div>
  <div class="done-scope">
    <div style="margin-top: 10px; width: 550px">
      <alert *ngIf="warningMessage" [alert]="alert" (alertClosed)="onDismissClick()"></alert>
    </div>
    <span class="bottom-container">
      <dx-button class="done-button" text="Done" (onClick)="onDoneClick()"></dx-button>
    </span>
  </div>
</div>
